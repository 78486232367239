const tableColsServiceGroupObjects = [
  {
    text: "ID",
    value: "id",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    width: 25
  },
  {
    text: "Город",
    renderFunction: value => value.city_details.name,
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },
  {
    text: "Название",
    value: "name",
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },
  {
    text: "Адрес объекта",
    value: "address",
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },
  {
    text: "Контроллеров",
    value: "controllers_count",
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },
  {
    text: "Компания",
    renderFunction: value => value?.organization_details?.name,
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },
  {
    text: "Дилер",
    // value: "diller",
    renderFunction: value => value?.organization_diller?.name,
    userRole: [10, 20, 30, 40, 50, 60, 70]
  }
];

export default tableColsServiceGroupObjects;
